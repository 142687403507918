.service-nav {
    a {
        font-weight: 600;
        text-decoration: none;
        color: $primary;
        max-width: 15rem;

        &.service-nav-item {
            display: flex;
            width: 100%;
            height: 5rem;
            padding: 5px;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            // transition: background-color 0.2s ease-in;
            border-bottom-width: 0px !important;
        }

        &.nav-active {
            color: $color-6;
            background-color: $primary;
        }

        @media screen and (max-width: 1040px) {
            max-width: 100%;
        }

        @media screen and (max-width: 930px) {
            &.service-nav-item {
                border-bottom-width: 1px !important;
            }
        }
    }

    a:hover {
        background-color: #c5463b38;
        cursor: pointer;
        color: $primary;
    }

    .lineInf {
        width: 100%;
        height: 1px;
        background: #707070;
        margin-top: 10px;
    }
}

.nav-active {
    color: $primary;
    .itemService {
        background-color: #c5463b38;
    }
}
