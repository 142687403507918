.project-details-entete {
    display: flex;
    flex-direction: column;
    align-items: center;

    .project-details-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12rem;
        height: 12rem;

        img {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            padding: 5px;
            border: 2px solid #c34839;
        }
    }

    .project-details-box {
        text-align: center;

        .project-details-titre {
            font-size: 2rem;
            font-family: "Building", sans-serif;
        }

        .project-details-info {
            color: #c34839;
            font-weight: 600 !important;
        }
    }
}
