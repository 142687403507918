.header {
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  z-index: 5;
  padding: 1.3rem 2rem;
  color: white;
}

.header-active {
  background-color: #c34839;
}

.logo {
  height: 2.5rem;
}

.nav-left {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

.nav-left-link {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 2rem;
  color: white;

  &-btns {
    display: none;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    > .btn {
      border-radius: 10rem;
      min-width: 10rem;
      &:hover {
        color: #c34839 !important;
      }
    }
  }

  @media screen and (max-width: 1084.99px) {
    &-btns {
      display: flex;
    }

    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 3.9rem;
    opacity: 1;
    transition: all 300ms ease-in-out;
    background: #c34839;
  }
}

.header-link {
  text-decoration: none;
  position: relative;
  color: inherit;
  transition: margin 200ms ease-in;
}

.nav-left-link .header-link.active:before,
.nav-left-link .header-link:hover:before {
  visibility: visible;
  width: 100%;
}

.nav-left-link .header-link:before {
  content: "";
  position: absolute;
  height: 2.3px;
  bottom: -2px;
  left: 0;
  background-color: white;
  visibility: hidden;
  width: 0;
  transition: all 0.3s ease-in-out 0s;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 10px;

  > .btn {
    border-radius: 10rem;
    min-width: 10rem;
    &:hover {
      color: #c34839 !important;
    }
  }

  @media screen and (max-width: 1084.99px) {
    display: none;
  }
}

.language-wrapper {
  display: flex;
  align-items: center;

  & > div:first-child {
    padding-right: 0.5rem;
    border-right: 1px solid white;
  }

  & > div:last-child {
    padding-left: 0.5rem;
  }
}

.language-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
  background-color: transparent;
  overflow: hidden;

  transition: all 100ms ease-in-out;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &:hover {
    background-color: white;
    opacity: 80%;
  }
}

.selcted-language {
  .language-flag {
    background-color: white;
  }
}
