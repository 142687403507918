.App{
    text-align: center;
    font-family: Helvetica;
    .buttoncard{
        color: $color-6;
    }
    
    .Layout{
       
        text-align: left;
        width: 50vw;
        
    }
    .bold{
        font-weight: 600;
        
    }
    .image-container{
        border: 1px solid grey;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        padding: 5px;
    }

    .contentuserinfo{
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        

    }
    .contentuserinfo1{
        display: flex;
        align-items: center;
        position: relative;
       
    }
    .contentuserinfo2{
        
       
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        
    }
    .linePost{
        

        width: 2px;
        height: 18px;
        background: grey;
    }
   
    .grey{
        color: grey;
    }
    .special{
        text-decoration: none;
        color: grey;
    }
    .userinfo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: .3em;
    }
    .userinfo i{
        margin: 0;
    }
    .post-content{
        margin-bottom: .3em;
    }
    .shared-post{
        border: 1px solid lightgrey;
        border-radius: 1%;
        margin-top: .3em;
    }
    .shared-post:hover{
        cursor: pointer;
    }
    .shared-post a{
        color: black;
        text-decoration: none;
    }
    .shared-post p,h6{
        padding: .2em;
    }
    .apost{
        text-decoration: none;
        color: black;
    }
    span{
        margin: .2em;
        margin-left:0;
    }
    i{
        margin: 1em;
    }
    i:hover{
        color: red;
    }
    img{
        width:100%;
        height: auto;
        border-radius: 1%;
    }
    p{
        font-weight: 250;
    }
    hr{
        border:0;
        width:100%;
        height: 1px;
        background-color: lightgrey;
    }

}
