.about {
    margin-top: 6em;
    .containerInf {
        transition: all 0.2s ease-in;
    }
    .services {
        margin-top: 60px;
    }
    .about-content {
        .about-head {
            .about-text {
                h1 {
                    font-size: 3em;
                    font-family: 'Building';
                }

                h4 {
                    font-size: 20px;
                    font-weight: bold;
                }

                p {
                    text-align: justify;
                    margin-top: 20px;
                }
            }

            .about-img {
                width: 100%;
                height: 100%;
                min-height: 300px;
                background-image: url("../assets/img/ip-6.jpeg");
                background-repeat: no-repeat;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
}
