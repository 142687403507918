@import "./components/sidebar";

.dashboard-section {
    font-family: "Karla", sans-serif;
    min-height: 100vh;

    & ul {
        padding: 0;
        margin: 0;
    }

    & li {
        list-style: none;
    }

    & .dashboard-section-content {
        transition: margin-left 0.2s ease-in;
        padding: 35px 50px 25px 50px;
    }
}

.nav-top {
    position: absolute;
    top: 1%;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.25);
    right: 0;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    min-width: 20%;
    background: white;
    z-index: 1;
}

.dash-projects-grid-container {
    display: grid;
    margin: 20px 50px 50px 50px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
}

.dash-project {
    position: relative;
    border-radius: 25px;
    width: 200px;
    height: 200px;
}

.dash-img-project {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.test {
    background-color: aqua;
}

.dash-project-image {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.dash-project-image .dash-img-project {
    transition: 0.3s ease-in;
    transform: scale(1);
}

.dash-project:hover .dash-project-image .dash-img-project {
    transform: scale(1.1);
}

.dash-project-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    width: 100%;
    margin: 10px 0;
}

.logo-top-project {
    position: absolute;
    top: 70%;
    left: 100px;
    width: 150px;
}

.dash-container {
    display: flex;
    margin-top: 100px;
    align-items: flex-start;
}

.dash-container-left {
    flex: 30% 1;
    border: 1px solid #80808094;
    margin-right: 2rem;
    border-radius: 1rem;
    padding: 1rem 0;
}

.dash-container-right {
    border-radius: 1rem;
}

.dash-container-projet-right {
    flex: 70% 1;
    border-radius: 1rem;
}

.dash-container-projet-left {
    flex: 30% 1;
    margin-right: 2rem;
    border-radius: 1rem;
    padding: 1rem 0;
}

.side-navigation-panel ul:last-child {
    border-bottom: none !important;
}
.side-navigation-panel ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px #b5b5b5 solid !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option-selected {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #c34839 !important;
    border-color: #c34839 !important;
    color: white !important;
    border-left-width: thick !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option-selected {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #c34839 !important;
    border-color: #c34839 !important;
    color: white !important;
    border-left-width: thick !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option
    .side-navigation-panel-select-option-wrap
    .side-navigation-panel-select-option-text {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
    border: none !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option {
    padding: 1rem 4rem !important;
}

.side-navigation-panel .side-navigation-panel-select-inner-option-text {
    font-size: 1.1rem !important;
    font-weight: 600;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option {
    padding: 0.75rem 1rem !important;
}

p.invest-text {
    position: relative;
}

p.invest-text::before {
    content: "";
    border: 1px solid grey;
    width: 41%;
    position: absolute;
    bottom: 7px;
}

.news-container {
    margin: 20px 0;
    border: 1px solid gray;
    padding: 20px;
}

.header-news-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 30%;
}

.dash-invest-grid-container {
    display: grid;
    margin: 20px 50px 50px 50px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
}

.dash-invest {
    display: flex;
    border: 1px solid #c34839;
    border-radius: 15px;
    box-shadow: 1px 1px 1px black;
    cursor: pointer;
    text-decoration: none;
}

.dash-invest-left {
    flex: 60%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.dash-invest-right {
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #c34839;
    border-radius: 100px 15px 15px 100px;
}

.message-line {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
}

.message-line .message-status {
    flex: 0 1 0.5rem;
    padding: 0.753rem;
}

.message-line .message-title {
    flex: 0 1 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0.753rem;
}

.message-line:last-child {
    border: 0 !important;
}

.message-line:hover {
    background-color: #c5463b10;
}

.message-line .message-content {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    & > span {
        padding: 1rem;
    }
}

.message-line .message-time {
    flex: 0 1 10rem;
    padding: 0.753rem;
}

.message-read .message-head .message-profil {
    width: 4rem;
    height: 4rem;
    border: 1px solid gray;
    border-radius: 50%;
    margin-right: 1.2rem;
}

.message-read .message-head .message-title > h3 {
    color: rgb(197, 71, 59);
    font-family: "building";
    line-height: 0.9;
}

.message-sender {
    font-family: "Building";
    font-size: 1.5rem;
    font-weight: 600;
}

.message-clock {
    display: flex;
    align-items: center;
}

.message-item {
    border-radius: 1rem;
    padding: 1rem;
}
