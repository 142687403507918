/**
  SECTION TITLE
**/
.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #585b60;
  font-size: 3rem;
  font-weight: 500;
  margin: auto;
  position: relative;
  width: fit-content;
  font-family: "Building", sans-serif;
  margin-bottom: 2rem;
}

.section-title::before {
  position: absolute;
  content: "";
  border-top: 3px solid #cdbdad;
  width: 50%;
  bottom: 10px;
}

/**
  SERVICES
**/
.section-service {
  width: fit-content;
  margin: 0 auto;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-left: 50px;
  margin-right: 50px;
}

.service-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  padding: 1rem;
}

.navMenuCache {
  display: none;

  @media screen and (max-width: 998px) {
    display: block;
  }
}

.service-icon {
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #c34839;
  padding: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 40px;
  box-shadow: 0 0 6px #c34839, 0 0 0 8px #dd7c73;

  @media screen and (max-width: 998px) {
    display: none;
  }
}

.service-content .service-content-title {
  font-family: "Building", sans-serif;
  color: rgb(197, 71, 59);
  margin-bottom: 0.4rem;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;

  @media screen and (max-width: 998px) {
    text-align: center;
  }
}

.service-content .service-content-text {
  font-weight: bold;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media screen and (max-width: 998px) {
    text-align: center;
  }
}

/**
  BANNER
**/
.section-banner {
  width: 100%;
  min-height: 520px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background-color: #c34839;
  position: relative;
}

.section-banner .seperator {
  position: absolute;
  width: 50%;
  left: 0;
  height: 100%;
  clip-path: polygon(0 0, 42vw 0%, 32vw 100%, 0 100%);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url("../assets/img/ip-14.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -30px center;

  @media screen and (max-width: 940px) {
    display: none;
  }
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.banner-wrapper {
  position: relative;
  width: 55%;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5em;

  @media screen and (max-width: 940px) {
    width: 100%;
    padding: 2em;
  }
}

.banner-content h5 {
  font-size: 3rem;
  font-family: "Building", sans-serif;
}

.banner-content p,
.banner-content ul li {
  font-size: 1.2rem;
}

.banner-button {
  margin-top: 2em;
  width: max-content;
}

.banner-button .next {
  margin-left: 0.4em;
  font-size: 2em;

  @media screen and (max-width: 940px) {
    font-size: 1.5em;
  }
}

.banner-button .previous {
  margin-right: 0.4em;
  font-size: 2em;

  @media screen and (max-width: 940px) {
    font-size: 1.5em;
  }
}

/**
  EXPERT
**/
.partner-text {
  text-align: center;
  padding: 0 10rem;
  font-size: 1.2rem;
  margin-bottom: 70px;
  line-height: 1.4;

  @media screen and (max-width: 930px) {
    padding: 0 1rem;
  }
}

.partner-box {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.partner-image {
  max-width: 25rem;
  height: 10rem;
  // margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

/**
  PROJET
**/
.projet-ip-item {
  min-height: 10rem;
  border-radius: 10px;
  background-color: #040404b8;
  position: relative;
  overflow: hidden;

  @media (max-width: 991.98px) {
    flex-direction: column;
  }

  .projet-ip-box {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @media (max-width: 991.98px) {
      flex: 0 1 100%;
    }
  }

  .projet-ip-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #bbbbbb;
    font-size: 0.9rem;
  }

  .projet-ip-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .projet-ip-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .projet-ip-content {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 2rem;
      color: #bbbbbb !important;
      font-size: 0.9rem;
    }

    h3 {
      font-family: "Building", sans-serif;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      margin-bottom: 0;
      color: white;
    }
  }

  // .projet-ip-button {
  //   padding: 10px;
  //   background-color: #c34839;
  //   width: max-content;
  //   color: white;
  //   border-radius: 5px;
  //   cursor: pointer;
  //   transition: 0.5s;
  // }

  // .projet-ip-button:hover {
  //   opacity: 0.8;
  // }

  // .projet-ip-details-invest {
  //   color: #c34839;
  //   font-weight: 600;
  // }

  .projet-ip-details-fav {
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    gap: 5px;
  }
}

.projet-ip-container {
  position: relative;
}

.projet-ip-wrapper {
  position: relative;
  margin: 0 5rem;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
}

.projet-ip-button-left,
.projet-ip-button-right {
  position: absolute;
  top: 50%;
  background: #c34839;
  border: 1px solid #c34839;
  color: white;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 12em;
  cursor: pointer;
  font-size: 1.3em;
}

.projet-ip-button-right {
  right: 15px;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.projet-ip-button-left {
  left: 15px;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

/**
  EVENT
**/
.event-wrapper {
  display: flex;
  flex-wrap: wrap;
  // max-width: 90%;
  align-items: center;
  justify-content: center;
}

.event-image {
  width: 100%;
  height: 18rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event-title {
  font-size: 1.3rem;
  line-height: 1.3;
  margin-bottom: 0.8rem;
}

.event-item {
  position: relative;
  overflow: hidden;
  border-radius: 0.8rem;
}

.event-hover {
  position: absolute;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000c7;
  transition: all 0.5s ease;

  p {
    @media screen and (max-width: 980px) {
      line-height: 1.2em;
      font-size: 3vw;
    }
  }
}

// .event-hover:hover {
//   opacity: 1;
//   top: inherit;
// }

.event-button {
  border: 1px solid #c34839;
  width: max-content;
  margin: 0.5rem;
  padding: 0.4rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.event-button:hover {
  background-color: #c34839;
}

/**
  CHIFFRE
**/
.section-chiffre {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.85)), url("../assets//img/assii.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  max-width: 100%;
}

.img-banner {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 70px;
}

/**
  SLIDER
**/
.slide-section {
  position: relative;
  // max-height: 100%;
}

.slider-buttons {
  position: absolute;
  bottom: 0em;
  left: 3em;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.carousel {
  min-height: 680px;
  background: #c34839;

  @media screen and (max-width: 1020px) {
    min-height: 480px;
  }
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  width: 35vw;
  bottom: initial;
  text-align: left;

  @media screen and (max-width: 1020px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: smaller;
    overflow: hidden;
    resize: vertical;
  }

  #title {
    font-family: "Building";
    color: white;
    margin-bottom: 0.5em;

    @media screen and (max-width: 720px) {
      margin: 0;
    }
  }

  .textEntete {
    display: block;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }
}

.bouttonEntete {
  display: block !important;

  @media screen and (max-width: 720px) {
    display: none !important;
  }
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 680px;

  .circle-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 35vw 70%;
    -webkit-clip-path: inset(-5vw -30vw -3vw 50vw round 50vw);
    clip-path: inset(-5vw -30vw -3vw 50vw round 50vw);

    @media screen and (max-width: 1020px) {
      width: 100%;
      height: 100%;
      position: absolute;
      background-position: center center;
      background-size: cover;
      top: 0;
      left: 0;
      clip-path: none;
    }
  }

  .circle-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4b4b4b;
    opacity: 0.2;
    -webkit-clip-path: inset(-3.5vw -30vw -10vw 45vw round 50vw);
    clip-path: inset(-3.5vw -30vw -10vw 45vw round 50vw);

    @media screen and (max-width: 1020px) {
      background: rgba($color: #000000, $alpha: 0.7);
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      clip-path: none;
    }
  }

  @media screen and (max-width: 1020px) {
    // background: transparent;
    height: 480px;
  }

  .filter {
    display: none;
    transition: all 0.2s ease-in;

    @media screen and (max-width: 1020px) {
      display: block;
      background: rgba($color: #000000, $alpha: 0.7);
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.prev-arrow,
.forward-arrow {
  width: 3.7em;
  height: 3.7em;
  color: aliceblue;
  cursor: pointer;
  padding: 0 0.5em;
  user-select: none;
  transition: 0.3s;
}

.prev-arrow {
  border-right: 3px solid white;
}

.slide-preview::before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(25, 23, 23, 0) 50%, rgba(25, 23, 23, 0.07) 100%);
}

.slide-preview {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.slide-content {
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 40vw;
  color: aliceblue;
  margin-left: 3em;
}

.slide-content > h1 {
  font-size: clamp(1rem, 8vw, 2rem);
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-bottom: 0.8em;
  font-family: "Building", sans-serif;
  font-size: 3em;
  margin-top: 0;
}

.slide-content > p {
  margin-bottom: 1.2em;
  font-size: 1.3em;
  line-height: 1.4em;
}

/**
  EXPERT
**/

.popup-content {
  justify-items: center;

  @media screen and (max-width: 980px) {
    width: 95% !important;
    margin-top: 80px !important;
  }

  p {
    @media screen and (max-width: 680px) {
      font-size: x-small;
      line-height: 1.5em;
    }

    @media screen and (min-width: 980px) {
      font-size: 1em;
    }
  }
}

.poste {
  @media screen and (max-width: 680px) {
    font-size: x-small;
    line-height: 1em;
  }

  @media screen and (min-width: 980px) {
    font-size: 1em;
  }
}

.expert-grid {
  // padding: 0 5rem;
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.98px) {
    grid-auto-flow: row;
    gap: 1.3rem;
  }
}

.expert-item {
  position: relative;
  transform: scale(0.9);
  margin: 0 10px;
  border-bottom: 3px solid #c34839;
}

.expert-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.expert-image-home {
  width: 100%;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  overflow: hidden;
}

.expert-name {
  color: #c34839;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0.3rem 0;
}

.expert-bibio {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.expert-button-view {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  cursor: pointer;
  transition: 0.5s;
}

.expert-button-view:hover {
  transform: scale(0.9);
}

.modal-experts {
  font-size: 1em;
  width: 100% !important;
}

.expert-modal-content {
  border-radius: 1em;
}

.popup {
  background-color: hotpink !important;
}

.modal-experts .modal-experts-content {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
}

.modal-experts-image {
  flex: 10%;
  width: 10%;
  height: 10%;
  margin: 0 20px;
}

.modal-experts-present {
  flex: 60% 1;
  line-height: 1.3rem;
  padding: 0 20px;
}

.modal-experts-present .name {
  margin-top: 0px;
  color: rgb(197, 71, 59);
  font-size: 1.2rem;
  margin-bottom: 0px;
  font-weight: 600;
}

.modal-experts-present .bibio {
  margin-top: 0px;
  color: rgb(197, 71, 59);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.modal-experts-contact {
  margin-top: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.modal-experts .modal-experts-actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal-experts .modal-experts-close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

/**
  OTHERS
**/
.btn-white {
  padding: 0.5em;
  font-size: 1.1em;
  border: 3px solid white;
  border-radius: 30px;
  color: white;
  background-color: transparent;
  cursor: pointer;
  width: max-content;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}
