#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    z-index: 1000;
    background: #fff;
    transition: all 5s linear;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #c34839;
    border-color: #c34839 transparent #c34839 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
