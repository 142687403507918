.infoProjet{
    background: white;
    padding: 10px;
   
    position: relative;
    .contour{
        border: 1px solid #707070;
        padding: 25px;
        border-radius: 24px;
        .element{
            margin-bottom: 20px;
            
            overflow: hidden;
            .label{
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                color:#585B60;
                margin-right: 0px;
                width: 240px;
                
               
                position: relative;
                &::after{
                    content: '';
                    width: 25%;
                    height: 1px;
                    background-color: #707070;
                    position: absolute;
                    bottom: 0px;
                    left: 1px;
                    border-radius: 20px;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
                }
            }
            .valeur{
                display: inline-block;
                color: #c34839;
                font-weight: 600;
                
            }
        }
        
            .button{
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;
            
            .fav{
                color: $primary;
                text-align:justify;
                font-weight: 600;
                .label{
                    margin-right: 10px;
                    font-size: 15px;
                }
                i{
                    font-weight: 600;
                }
               
            }
            
            
        

        }
        
        
    }

}