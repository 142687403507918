.auth-container {
    display: -webkit-box;
    height: 100vh;
}

.auth-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.auth-img {
    height: 100%;
    width: 55%;
    background-size: 100% 100%;
    background-position-x: center;
    background-position-y: center;
}

.auth-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(84, 20, 13, 0.7);
}

.auth-present {
    width: 50%;
    color: white;
}

.auth-present h1 {
    font-family: "Building";
    font-size: 3em;
}

.auth-present p {
    font-size: 1.5em;
    line-height: 1.4;
}

.auth-form {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 50%;
    height: 100%;
    // align-items: center;
    // border-top-left-radius: 50px;
    // border-bottom-left-radius: 50px;
    background-color: white;
    z-index: 2;
    overflow: hidden;

    @media screen and (max-width: 930px) {
        width: 100%;
        padding: .8em !important;
        text-align: center;

        .login-form {
            height: 90%;
        }
    }
}

.auth-content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
}

.auth-head {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin: 2em 0 2em 0;
}

.auth-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 0.9em;
    background: #c3483952;
    border-radius: 2em;
    overflow: hidden;
}

.auth-head .auth-title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 600;
}

.auth-nav-item {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6em 0;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.auth-nav-item:hover {
    color: white;
    background: #c14a3ea6;
}

.auth-nav-item.active {
    background: #c34839;
    color: white;
}

.login-form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    font-size: 0.9em;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.5em;
}

.login-form::-webkit-scrollbar {
    width: 0.5em;
}

.login-form::-webkit-scrollbar-track {
    background: #e8f0fe;
}

.login-form::-webkit-scrollbar-thumb {
    background-color: #c34839;
    border-radius: 20px;
}

.select-data {
    padding: 5px;
}

.form-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: smaller;
    margin: 2em 0.4em;
}

.form-end-register {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em .4em 0em .4em;
}

input,
select {
    font-family: "Karla";
    font-size: 1em;
}

.login-divider {
    margin: 20px;
}

.login-divider::before,
.login-divider::after {
    display: inline-block;
    vertical-align: middle;
    height: 1px;
    width: 185px;
    margin: 0 20px;
    background-color: #cdbdad;
    content: "";
}

.img-end {
    width: 80px;
    transition: 1s;
    cursor: pointer;
}

.img-end:hover {
    transform: scale(1.1);
}

/* .btn {
    padding: 0.6em;
    background-color: #c34839;
    color: white;
    font-size: 1em;
    border: 1px solid #c34839;
    border-radius: 1em;
    font-family: "Karla";
    cursor: pointer;
} */
